var apiDomain = 'https://api-dev.mymedhub.net';

if (window.location.host === 'localhost:3000' || window.location.host === 'admin-dev.mymedhub.net') {
    apiDomain = 'https://api-dev.mymedhub.net';
}

const Config = {
    apiDomain: apiDomain,
    version: '1.0',
    appName: 'MyMedHub',
};

export default Config;
